<template>
  <div>
    <tab-bar-wrapper></tab-bar-wrapper>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :contextMenuItems="contextMenuItems"
        :commentList="commentList"
        :componentName="$options.name"
        :columns="columns"
        :query="query"
        :filter="filters"
        :annotations="annotations"
        :nested-query="nestedQuery"
        :nested-filters="nestedFilters"
        :nested-annotations="nestedAnnotations"
        :nested-grid="true"
        :nested-columns="nestedColumns"
        :is-row-master-function="rowMasterFunction"
        @ready="ready"
        @setComments="setComments"
        @cell-key-down="gridKbEventSender"
      >
      </grid-wrapper>
    </div>
    <action-bar-wrapper
      :commentList="commentList"
      @saveComments="saveComments"
    >
    </action-bar-wrapper>
    <!-- Vuex Dialog -->
    <v-dialog
      v-model="showDialog"
      lazy
      persistent
      width="95%"
    >
      <lock-reason
        v-if="localDialog"
        :nodes="selectedNodes"
        @close="resetEditables"
        @save="save"
        @saveLock="saveLock"
      ></lock-reason>
    </v-dialog>
  </div>
</template>

<script>
// import json from '../../data1.json'
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import grid from '../../components/mixins/grid'
import columns from '../../components/mixins/columns'
import buysellhistory from '../../components/modals/buysellhistory'
import mutateItems from '../../api/graphql/mutationsJS/mutateItems'
import {
  GET_SYSTEMS,
  GRID_GET_ADD_ON_ITEMS_FOR_SYSTEM
} from '@/api/graphql/Constants/Inventory'
import { COLUMNS__INVENTORY_SYSTEMS, COLUMNS__INVENTORY_SYSTEMS_NESTED } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__INVENTORY_SYSTEMS } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__INVENTORY_SYSTEMS } from '@/lib/agGridKbShortCuts'
import { RowMasterFunctionForItemCountAnnotation } from '@/lib/helpers'
import lockReason from '@/components/sheets/lockReason.vue'
export default {
  name: 'system',
  mixins: [grid, columns, mutateItems],
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper,
    'lock-reason': lockReason
  },
  computed: {
    showDialog () {
      return this.dialog.app === 'systems'
    }
  },
  watch: {
    activeSheet: function () {
      this.showSheet = typeof this.activeSheet === typeof 'string'
    }
  },
  data () {
    return {
      quickSaveEnabled: false,
      selectedNodes: [],

      commentList: {
        'inventory_comment': {
          name: 'inventory_comment',
          label: 'Internal',
          text: '',
          editable: true,
          show: true
        },
        'system_logs': {
          name: 'system_logs',
          label: 'System',
          text: '',
          editable: false,
          show: true
        },
        'condition_comment': {
          name: 'condition_comment',
          label: 'Condition',
          text: '',
          editable: true,
          show: true
        },
        'lock_reason': {
          name: 'lock_reason',
          label: 'Lock Comment',
          text: '',
          editable: false,
          show: false
        },
        'id': false
      },

      showBreakdownDialog: false,
      breakdownPart: {},
      savingBreakdown: false,

      // query: columns => GRID__GET_SYSTEMS(columns),
      query: GET_SYSTEMS,
      filters: [],
      annotations: [
        {
          name: 'itemCount',
          aggr_type: 'COUNT',
          aggr_field: 'add_on_items'
        }
      ],

      nestedQuery: GRID_GET_ADD_ON_ITEMS_FOR_SYSTEM,
      nestedFilters: (id) => [{ key: 'add_on_items__system__id', value: id }],
      nestedAnnotations: [],

      buySellPart: {},

      contextMenuItems: (params) => CONTEXT_MENU__INVENTORY_SYSTEMS(params, this.user.initials, this.contextMenuCallback),

      columns: COLUMNS__INVENTORY_SYSTEMS,
      nestedColumns: COLUMNS__INVENTORY_SYSTEMS_NESTED
    }
  },
  methods: {
    contextMenuCallback (params) {
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__INVENTORY_SYSTEMS(params, this.user.initials, this), [])
    },

    resetEditables () {
      this.gridMode = 'read'
      this.editableNodes = []
      this.activeSheet = null
      this.quickSaveEnabled = false
      this.$store.dispatch('data/clearEditions')
    },

    rowMasterFunction (item) {
      return RowMasterFunctionForItemCountAnnotation(item.annotations)
    },

    addOobMgmtSerial () {
      // TODO: Create dialog for this
    },

    showBSHistory (item) {
      this.$store.dispatch('grid/setBuySellPart', { pn: item.part.pn, id: item.part.id })
      this.$store.dispatch('grid/changeDialog', { app: 'topbar', component: 'buysellhistory' })
    },

    save () {
      this.resetEditables()
      this.gapi.redrawRows()
    },

    saveLock (comment) {
      this.commentList.lock_reason.show = true
      this.commentList.lock_reason.text = comment
    },

    lock (selectedNodes) {
      this.selectedNodes = selectedNodes
      this.setUpDialog('lock-reason')
    },

    async unlock (selectedNodes) {
      for (const selectedNode in selectedNodes) {
        const node = selectedNodes[selectedNode]
        await this.unLockItem(node.data.id)
        node.data.locked = false
        node.data.lock_reason = ''
        this.commentList.lock_reason.text = ''
      }
      this.commentList.lock_reason.show = false
      this.$store.dispatch('notifications/createSnackbar', {
        message: 'Unlocked item successfully.',
        color: 'success',
        timeout: 2000,
        canClose: false,
        top: false
      })
      this.save()
    }
  },

  beforeMount () {
    if (this.$route.query?.key ?? false) {
      this.filters.push(this.$route.query)
    }
  }

}
</script>
